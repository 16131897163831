import Vue from 'vue'
import './plugins/fontawesome'
import App from './App.vue'
import Login from './views/Login'
import Report from './views/Report'
const Client = () =>
    import ("./views/Client")

import '@fortawesome/fontawesome-free/js/all.js'
import VueCurrencyInput from 'vue-currency-input'
import VueNumeric from 'vue-numeric'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import VueToast from 'vue-toast-notification'
import axios from 'axios'
import i18n from './i18n'
import VueCookies from 'vue-cookies'
import Tools from './tools'

//import VueNativeSock from 'vue-native-websocket'


if (process.env.NODE_ENV == 'development') {
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
} else { // Production
    Vue.BASE_URL = Vue.prototype.BASE_URL = '';
    // Vue.BASE_URL = Vue.prototype.BASE_URL = '/dna_gest_com_arcenciel';
}
// Vue.NOTIF = Vue.prototype.NOTIF = new Audio(Vue.BASE_URL + '/assets/sounds/pristine-609.mp3');
// Vue.SENT = Vue.prototype.SENT = new Audio(Vue.BASE_URL + '/assets/sounds/tweet-416.mp3');




Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(datePicker);
Vue.use(VueToast);
Vue.use(VueCookies);




let instance = Vue.$toast.open('You did it!');
instance.dismiss();
Vue.$toast.clear();


import router from './router'
import routerclient from './routerclient'

Vue.config.productionTip = false

const pluginOptions = {
    globalOptions: { currency: null }
};

Vue.use(VueCurrencyInput, pluginOptions)
Vue.use(VueNumeric)

// WebSocket config
// console.log("Starting connection to WebSocket Server")
//Vue.use(VueNativeSock, 'ws://' + window.location.hostname + ':8089', {
//    reconnection: true,
//    reconnectionDelay: 3000,
//    format: 'json'
//})
Vue.$socket = Vue.prototype.$socket;



// this.connection.onopen = function(event) {
//     console.log(event)
//     console.log("Successfully connected to the echo websocket server...")
// }

axios.get(Vue.BASE_URL + "/users/getuserconfig").then(function() {});
var user = {
    users_gest_com_id: Vue.$cookies.get("users_gest_com_id"),
    users_gest_com_role: Vue.$cookies.get("users_gest_com_role"),
    users_gest_com_nom: Vue.$cookies.get("users_gest_com_nom"),
    users_gest_com_statut: Vue.$cookies.get("users_gest_com_statut"),
    clients_gc_id: Vue.$cookies.get("clients_gc_id"),
    clients_gc_role: Vue.$cookies.get("clients_gc_com_role"),
    clients_gc_nom: Vue.$cookies.get("clients_gc_nom"),
    clients_gc_statut: Vue.$cookies.get("clients_gc_statut"),
    users_gest_com_statut_caisse: Vue.$cookies.get("users_gest_com_statut_caisse"),
    users_gest_com_token: Vue.$cookies.get("users_gest_com_token"),
};

var vm = null;
if (!Tools.Valider(user.users_gest_com_id, user.users_gest_com_token) && !Tools.Valider(user.clients_gc_id, user.users_gest_com_token)) {
    vm = new Vue({
        router,
        render: h => h(Login),
        i18n
    }).$mount('#app');
} else if (Tools.Valider(user.users_gest_com_id, user.users_gest_com_token) && user.users_gest_com_statut != "EXIST") { // || user.statut_caisse == "NOT EXIST"
    vm = new Vue({
        router,
        render: h => h(Report, {
            props: {
                users_info: user
            }
        }),
        i18n
    }).$mount('#app');
} else if (Tools.Valider(user.clients_gc_id, user.users_gest_com_token)) {
    vm = new Vue({
        router: routerclient,
        render: h => h(Client, {
            props: {
                users_info: user
            }
        }),
        i18n
    }).$mount('#app');
} else {
    vm = new Vue({
        router,
        render: h => h(App, {
            props: {
                users_info: user
            }
        }),
        i18n
    }).$mount('#app');
}





router.beforeEach((to, from, next) => {
    axios.get(Vue.BASE_URL + "/users/getuserconfig").then(function() {});
    var user = {
        users_gest_com_id: Vue.$cookies.get("users_gest_com_id"),
        users_gest_com_role: Vue.$cookies.get("users_gest_com_role"),
        users_gest_com_nom: Vue.$cookies.get("users_gest_com_nom"),
        users_gest_com_statut: Vue.$cookies.get("users_gest_com_statut"),
        clients_gc_id: Vue.$cookies.get("clients_gc_id"),
        clients_gc_role: Vue.$cookies.get("clients_gc_com_role"),
        clients_gc_nom: Vue.$cookies.get("clients_gc_nom"),
        clients_gc_statut: Vue.$cookies.get("clients_gc_statut"),
        users_gest_com_statut_caisse: Vue.$cookies.get("users_gest_com_statut_caisse"),
        users_gest_com_token: Vue.$cookies.get("users_gest_com_token"),
    };
    vm.$emit('change-load', 'loading', user);
    if ((!Tools.Valider(user.users_gest_com_id, user.users_gest_com_token) && !Tools.Valider(user.clients_gc_id, user.users_gest_com_token)) || user.users_gest_com_statut != "EXIST") {
        location.reload();
    } else {
        next();
    }

})

router.afterEach(() => {
    var user = {
        users_gest_com_id: Vue.$cookies.get("users_gest_com_id"),
        users_gest_com_role: Vue.$cookies.get("users_gest_com_role"),
        users_gest_com_nom: Vue.$cookies.get("users_gest_com_nom"),
        users_gest_com_statut: Vue.$cookies.get("users_gest_com_statut"),
        clients_gc_id: Vue.$cookies.get("clients_gc_id"),
        clients_gc_role: Vue.$cookies.get("clients_gc_com_role"),
        clients_gc_nom: Vue.$cookies.get("clients_gc_nom"),
        clients_gc_statut: Vue.$cookies.get("clients_gc_statut"),
        users_gest_com_statut_caisse: Vue.$cookies.get("users_gest_com_statut_caisse"),
        users_gest_com_token: Vue.$cookies.get("users_gest_com_token"),
    };
    vm.$emit('change-load', 'loaded', user);
})

vm.$emit('change-load', 'loading', user);
vm.$emit('change-load', 'loaded', user);