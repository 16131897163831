import Vue from 'vue'
import VueRouter from 'vue-router'
const Facturesreglementclient = () =>
    import ("../views/Facturesreglementclient/Facturesreglementclient.vue");

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Dashboardclient',
        component: Facturesreglementclient
    },
    {
        path: '/clientreglements',
        name: 'Facturesreglementclient',
        component: () =>
            import ('../views/Facturesreglementclient/Facturesreglementclient.vue')
    },
    {
        path: '/clientarticles',
        name: 'Articlesclient',
        component: () =>
            import ('../views/Articlesclient/Articlesclient.vue')
    },
    {
        path: '*',
        redirect: '/'
    }

]

const routerclient = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

// routerclient.replace({ path: '*', redirect: '/' })
export default routerclient