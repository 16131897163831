import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment';

import { Line, mixins } from 'vue-chartjs'

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    extends: Line,
    mixins: [mixins.reactiveProp],
    props: ['chartData', 'options'],
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: this.$t('tableau_de_bord.tableau_de_bord'),
            loaded: false,
            motCle: "",
            filtreDate: {
                dateDebut: "",
                dateFin: ""
            },
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            document_de_vente: {
                total_facture: 0,
                total_note_achat: 0,
                total_proforma: 0,
                nombre_article_vendu: 0
            },
            etat_client: {
                montant_total_a_payer: 0,
                montant_total_deja_payer: 0,
                montant_reste_a_payer: 0,
                total_montant_avec_remise_detail: 0,
                montant_total_remise: 0
            },
            etat_fournisseur: {
                montant_total_a_payer: 0,
                montant_total_deja_payer: 0,
                montant_reste_a_payer: 0
            },
            etat_depenses: {
                total_depense: 0
            },
            etat_article_detail: [],
            total_article_detail: {
                total_quantite: 0,
                total_revient: 0,
                total_vente: 0,
                total_remise: 0,
                total_benefice: 0
            },
            etat_degat: {
                total_degat: ""
            },
            montant_total_detail: {
                total_qte: "0",
                total_revient: "0",
                total_remise: "0",
                total_montant_avec_remise: "0",
                total_benefice: "0",
            },
            fond_de_caisse: "",
            reste_caisse_du_jour: "0",
            point_de_ventes_id: "",
            point_de_ventes: "",
            montant_total: "",
            remise_total: "",
            montant_total_reglement_client: "",
            montant_total_reglement_fournisseur: "",
            montant_total_paiement_credit_client: "",
            detail_reglement_mode_paiement: [],
            detail_reglement_credit_client_mode_paiement: [],
            detail_reglement_fournisseur_mode_paiement: [],
            vuetable: {
                moreParams: {},
                fields: [{
                        name: 'designation',
                        title: this.$t('tableau_de_bord.designation_article'),
                        dataClass: 'text-left',
                        sortField: 'designation',
                    },

                    {
                        name: 'quantite',
                        title: this.$t('tableau_de_bord.quantite_article'),
                        dataClass: 'text-right text-bold',
                        sortField: 'quantite',
                        titleClass: 'text-center',
                        width: '75px',
                    },
                    // {
                    //     name: 'pau_ttc',
                    //     title: 'PU',
                    //     dataClass: 'text-right',
                    //     sortField: 'pau_ttc',
                    //     titleClass: 'text-center',
                    //     width: '100px',
                    // },
                    {
                        name: 'montant_revient',
                        title: this.$t('tableau_de_bord.total_revient'),
                        dataClass: 'text-right text-bold',
                        sortField: 'montant_revient',
                        titleClass: 'text-center',
                        width: '130px',
                    },
                    {
                        name: 'remise',
                        title: this.$t('tableau_de_bord.total_remise_article'),
                        dataClass: 'text-right text-bold text-danger',
                        sortField: 'remise',
                        titleClass: 'text-center',
                        width: '110px',
                    },
                    {
                        name: 'montant_total',
                        title: this.$t('tableau_de_bord.total_monant_avec_remise'),
                        dataClass: 'text-right text-bold',
                        sortField: 'montant_total',
                        titleClass: 'text-center',
                        width: '130px'
                    },
                    {
                        name: 'montant_benefice',
                        title: this.$t('tableau_de_bord.benefice_article'),
                        dataClass: 'text-right text-bold text-success',
                        sortField: 'montant_benefice',
                        titleClass: 'text-center',
                        width: '130px'
                    },
                    {
                        name: 'benefice_pourcentage',
                        title: this.$t('tableau_de_bord.benefice_pourcentage'),
                        dataClass: 'text-right text-bold ',
                        sortField: 'benefice_pourcentage',
                        titleClass: 'text-center',
                        width: '100px'
                    },
                    {
                        name: 'remise_pourcentage',
                        title: this.$t('tableau_de_bord.remise_pourcentage'),
                        dataClass: 'text-right text-bold ',
                        sortField: 'remise_pourcentage',
                        titleClass: 'text-center',
                        width: '100px'
                    },


                ],
                sortOrder: [
                    { field: 'articles_id', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-primary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Point de vente': 'nom_point_vente',
                    'Référence': 'ref_article',
                    'Désignation': 'designation',
                    'Quantité': 'quantite',
                    'Montant': 'toal_montant',
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            users: [],
            users_id: "",

        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        loadPointDeVente() {
            axios.get(this.BASE_URL + "/pointvente/getall/").then(response => {
                //console.log(response.data[0]);
                this.point_de_ventes = response.data;
            });
        },
        setFilterData() {

            var id_utilisateur = this.$parent.role_user == "VENDEUR" ? this.$parent.id_user : this.users_id;
            axios.get(this.BASE_URL + "/dashboard/etatdocumentvente?start=" + this.filtreDate.dateDebut + "&end=" + this.filtreDate.dateFin + "&point_de_ventes_id=" + this.point_de_ventes_id + "&users_id=" + id_utilisateur).then(response => {
                //console.log(response.data[0]);
                this.document_de_vente = response.data;
                console.log(response.data);
                this.etat_client = this.document_de_vente.etat_client;
                this.etat_depenses.total_depense = this.document_de_vente.montant_total_depense;
                this.etat_fournisseur = this.document_de_vente.etat_fournisseur;
                this.etat_degat = this.document_de_vente.etat_degat;

                this.detail_reglement_mode_paiement = this.document_de_vente.detail_reglement_mode_paiement;
                this.detail_reglement_credit_client_mode_paiement = this.document_de_vente.detail_reglement_credit_client_mode_paiement;
                this.detail_reglement_fournisseur_mode_paiement = this.document_de_vente.detail_reglement_fournisseur_mode_paiement;

                this.montant_total_reglement_client = this.document_de_vente.montant_total_reglement_client;
                this.montant_total_reglement_fournisseur = this.document_de_vente.montant_total_reglement_fournisseur;

                this.montant_total_detail = this.document_de_vente.montant_total_detail;

                this.montant_total_paiement_credit_client = this.document_de_vente.detail_reglement_credit_client;

                this.fond_de_caisse = this.document_de_vente.fond_de_caisse;
                this.reste_caisse_du_jour = (Number(this.fond_de_caisse) + Number(this.montant_total_reglement_client)) - (Number(this.montant_total_reglement_fournisseur) + Number(this.etat_depenses.total_depense));
            });
            this.setFilter();
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;
            this.vuetable.moreParams.users_id = this.$parent.role_user == "VENDEUR" ? this.$parent.id_user : this.users_id;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        loadUsers: function() {
            axios.get(this.BASE_URL + "/dashboard/fetchusers/").then(response => {
                this.users = response.data;
            });
        }

    },
    computed: {

    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;

        this.loadUsers();
        this.loadPointDeVente();
        this.setFilterData();
        this.setFilter();

        // console.log("--------------------");
        // console.log(this.$parent.role_user);
        // console.log(this.$parent.id_user);

    },
    watch: {

    },
    mounted() {
        // this.renderChart(this.chartData, this.options);
    }
}