<style scoped>
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~vue-toast-notification/dist/theme-sugar.css";
@import "~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
</style>
<template id="main-app">
  <div class="page-wrapper chiller-theme toggled">
    <a
      id="show-sidebar"
      class="btn btn-sm btn-light text-left"
      href="javascript:void(0)"
      style="border-radius: 0px; color: brown"
    >
      <i class="fas fa-bars"></i>
    </a>
    <nav id="sidebar" class="sidebar-wrapper">
      <div class="sidebar-content">
        <div class="sidebar-brand">
          <a href="#">
            <img
              class="img-responsive"
              src="../public/assets/img/Logo-Tsaravidy.jpg"
              alt="Logo"
              style="max-width: 100%; height: auto"
            />
            <!-- :src="logo_soc==''?'../public/assets/img/Logo-Tsaravidy.jpg':BASE_URL+'/public/assets/img/'+logo_soc" -->
          </a>
          <div id="close-sidebar">
            <i class="fas fa-bars"></i>
          </div>
        </div>
        <div class="sidebar-header" style="margin-top: 70px">
          <div class="user-pic">
            <img
              class="img-responsive img-rounded"
              src="../public/assets/img/user.jpg"
              alt="User picture"
            />
          </div>
          <div class="user-info">
            <span class="user-name">
              <strong>{{ nom_user }}</strong>
            </span>
            <span class="user-role">{{ role_user }}</span>
            <span class="user-status" style="cursor: pointer">
              <i class="fa fa-caret-right"></i>
              <span
                ><a @click="doLogout" style="color: #888; cursor: pointer">
                  Se deconnecter</a
                ></span
              >
            </span>
          </div>
        </div>

        <div class="sidebar-menu">
          <ul>
            <li class="header-menu">
              <span>{{ $t("nav_menu.home") }}</span>
            </li>
            <li>
              <router-link to="/dashboard">
                <i class="fa fa-users"></i>
                <span>{{ $t("nav_menu.dashboard") }}</span>
              </router-link>
            </li>

            <li class="header-menu">
              <span>{{ $t("nav_menu.structures") }}</span>
            </li>
            <li>
              <router-link to="/articlesfamille">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.famille_article") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/articles">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.articles") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/fournisseurs">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.fournisseur") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/clients">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.client") }}</span>
              </router-link>
            </li>
            <li class="header-menu">
              <span>{{ $t("nav_menu.traitement") }}</span>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'VENDEUR' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/factures">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.documents") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/bondecommande">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.bon_de_commande") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/bondelivraison">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.bon_de_livraison") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/ventedirect">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.vente_fournisseur_client") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/bondecommandefournisseur">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.bon_de_commande_fournisseur") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/inventaire">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.saisie_inventaire") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/approvisionnement">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.approvisionnement") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'VENDEUR' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/transfertstock">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.transfert_de_stock") }}</span>
              </router-link>
            </li>

            <li>
              <router-link to="/depenses">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.depenses") }}</span>
              </router-link>
            </li>

            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'VENDEUR' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/degats">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.degats") }}</span>
              </router-link>
            </li>

            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'VENDEUR' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/sortiestock">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.sortiestock") }}</span>
              </router-link>
            </li>

            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'VENDEUR' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/echeance">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.echeance") }}</span>
              </router-link>
            </li>

            <li class="header-menu">
              <span>{{ $t("nav_menu.tresorerie") }}</span>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'VENDEUR' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/tresoreries">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.tresorerie_liste") }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/mouvementsdecaisse">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.tresorerie_mouvement") }}</span>
              </router-link>
            </li>

            <li class="header-menu">
              <span>{{ $t("nav_menu.etats") }}</span>
            </li>
            <!-- <li v-if="role_user=='dévéloppeur' || role_user=='ADMIN'">
              <router-link to="/">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t('nav_menu.etat_recap') }}</span>
              </router-link>
            </li> -->
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/ventesdetail">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.etat_article_detail") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/etatsclients">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.etat_client") }}</span>
              </router-link>
            </li>
            <!-- || role_user=='ADMIN' -->
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/etatfournisseurs">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.etat_fournisseur") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/etatdepenses">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.etat_depenses") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/etatsdegats">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.etat_degat") }}</span>
              </router-link>
            </li>
            <li v-if="role_user == 'dévéloppeur' || role_user == 'SUPER ADMIN'">
              <router-link to="/stockenvaleur">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.stock_en_valeur") }}</span>
              </router-link>
            </li>
            <!-- <li v-if="role_user=='dévéloppeur' || role_user=='ADMIN'">
              <router-link to="/">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t('nav_menu.chiffre_affaire') }}</span>
              </router-link>
            </li> -->

            <li class="header-menu">
              <span>{{ $t("nav_menu.historique") }}</span>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/reglementhistorique">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.historique_reglement") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'VENDEUR' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/mouvements">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.mouvement_stock") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE VENTE' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/inventaire">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.historique_inventaire") }}</span>
              </router-link>
            </li>
            <li
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'RESPONSABLE APPRO' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <router-link to="/approvisionnement">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.historique_entrees") }}</span>
              </router-link>
            </li>

            <li
              class="header-menu"
              v-if="
                role_user == 'dévéloppeur' ||
                role_user == 'ADMIN' ||
                role_user == 'SUPER ADMIN'
              "
            >
              <span>{{ $t("nav_menu.parametres") }}</span>
            </li>
            <li>
              <router-link
                to="/infosociete"
                v-if="
                  role_user == 'dévéloppeur' ||
                  role_user == 'ADMIN' ||
                  role_user == 'SUPER ADMIN'
                "
              >
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.parametres_societe") }}</span>
              </router-link>
            </li>
            <li v-if="role_user == 'dévéloppeur'">
              <router-link to="/pointdevente">
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.point_de_vente") }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="/magasin"
                v-if="role_user == 'dévéloppeur' || role_user == 'SUPER ADMIN'"
              >
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.magasin_stockage") }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="/importdata"
                v-if="
                  role_user == 'dévéloppeur' ||
                  role_user == 'ADMIN' ||
                  role_user == 'SUPER ADMIN'
                "
              >
                <i class="fas fa-angle-right"></i>
                <span>{{ $t("nav_menu.import") }}</span>
              </router-link>
            </li>

            <li
              class="header-menu"
              v-if="role_user == 'dévéloppeur' || role_user == 'SUPER ADMIN'"
            >
              <span>{{ $t("nav_menu.sauvegarde") }}</span>
            </li>
            <li v-if="role_user == 'dévéloppeur' || role_user == 'SUPER ADMIN'">
              <a href="database/backup" target="_blank">
                <i class="fa fa-database"></i>
                <span>{{ $t("nav_menu.sauvegarder_bdd") }}</span>
              </a>
            </li>
          </ul>
        </div>
        <!-- sidebar-menu  -->
      </div>
      <!-- sidebar-content  -->
    </nav>

    <!-- sidebar-wrapper  -->
    <main class="page-content">
      <div class="all-content">
        <header>
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand" href="#">
              <i class="fas fa-store-alt"></i>
              MAGASIN <i class="fas fa-angle-right"></i>
              <!--strong> Tsaravidy Morafeno</strong> &nbsp;<i
                class="fas fa-angle-right"
              ></i-->
              <span> {{ titre }}</span>
            </a>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav mr-auto"></ul>
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a
                    class="btn btn-light"
                    type="button"
                    title="Document de vente"
                  >
                    <i class="fas fa-shopping-cart fa-2x"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="btn btn-light"
                    type="button"
                    title="Bon de livraison"
                  >
                    <i class="fas fa-truck-loading fa-2x"></i>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="btn btn-light"
                    type="button"
                    title="Règlement client"
                  >
                    <i class="fas fa-file-invoice-dollar fa-2x"></i>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="btn btn-light dropdown-toggle color-newrest-gris"
                    title="Langues"
                    type="button"
                    data-toggle="dropdown"
                    href="javascript:void(0);"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-globe fa-2x"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby=""
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="switchLanguage('fr')"
                      :class="{
                        'bg-secondary text-white': $i18n.locale == 'fr',
                      }"
                      ><i class="fas fa-angle-right"></i> Français</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="switchLanguage('en')"
                      :class="{
                        'bg-secondary text-white': $i18n.locale == 'en',
                      }"
                      ><i class="fas fa-angle-right"></i> English</a
                    >
                  </div>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="btn btn-light dropdown-toggle color-newrest-gris"
                    title="Paramètres utilisateurs"
                    type="button"
                    data-toggle="dropdown"
                    href="javascript:void(0);"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-users-cog fa-2x"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby=""
                  >
                    <a class="dropdown-item" href="#"
                      ><i class="fas fa-user"></i> Mon profil</a
                    >
                    <!-- <a class="dropdown-item" href="#"
                      ><i class="fas fa-users-cog"></i> Groupe d'utilisateur</a
                    > -->
                    <router-link class="dropdown-item" to="/utilisateurs"
                      ><i class="fas fa-users"></i> Utilisateur du
                      logiciel</router-link
                    >
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="doLogout"
                      ><i class="fas fa-sign-out-alt"></i> Se déconnecter</a
                    >
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <div class="container-fluid">
          <router-view @change-page="onChangePage" />
        </div>
        <div v-if="showLoading" class="dna-loader">
          <img :src="base_url + '/public/assets/img/loading.gif'" />
        </div>
      </div>
    </main>
    <!-- page-content" -->
  </div>
</template>
  
<script>
import axios from "axios";
import $ from "jquery";

export default {
  data: function () {
    return {
      titre: "",
      showLoading: false,
      nom: "DNA Webhosting",
      role: "",
      id_user: "",
      logo_soc: "",
      base_url: this.BASE_URL,
    };
  },
  methods: {
    onChangePage: function (pageTitle) {
      this.titre = pageTitle;
      console.log(this.titre);
    },
    onChangeLoad: function (status, userData) {
      // console.log("LOADING");
      if (status == "loading") {
        this.showLoading = true;
        console.log("LOADING");
      } else {
        this.showLoading = false;
        console.log("LOADED");
      }
      this.nom = userData.users_gest_com_nom;
      this.role = userData.users_gest_com_role;
      this.logo_soc = userData.users_gest_com_logo;
      this.id_user = userData.users_gest_com_id;

      if (
        window.matchMedia("only screen and (max-width: 760px)").matches == true
      ) {
        $(".page-wrapper").removeClass("toggled");
      }
    },
    switchLanguage: function (lang) {
      this.$i18n.locale = lang;
      localStorage.current_lang = lang;
    },
    doLogout: function () {
      var that = this;
      axios.get(this.BASE_URL + "/users/logout").then(function () {
        that.$router.push("/login");
      });
    },
  },
  created: function () {
    this.$parent.$on("change-load", this.onChangeLoad);
    if (localStorage.current_lang) {
      this.$i18n.locale = localStorage.current_lang;
    }
  },
  computed: {
    nom_user() {
      if (this.nom == "" || this.nom == null) {
        return "";
      }
      return this.nom.replace("+", " ");
    },
    role_user() {
      if (this.role == "" || this.role == null) {
        return "";
      }
      return this.role.replace("+", " ");
    },
  },
};
</script>
<style>
</style>